<template>
    <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
      <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
            <div class="vx-row no-gutter justify-center items-center">
  
              <div class="vx-col hidden lg:block">
                <center><img src="@/assets/logo.png" alt="login" class="mx-auto lg:w-1/4"></center>
              </div>
  
              <div class="vx-col mx-auto lg:w-2/3">
                <div class="p-8 login-tabs-container">
  
                  <div class="mb-4">
                    <h1 class="text-4xl">FORGOTTEN PASSWORD<br/><br/></h1>
                    <p>PLEASE ENTER THE ONE TIME PASSWORD CODE WHICH WAS SENT TO YOUR EMAIL ADDRESS AND YOUR NEW PASSWORD</p>
                  </div>
  
                  <div>

                    <md-field>
                      <label>Email</label>
                      <md-input v-model="email"></md-input>
                    </md-field>
  
                    <md-field>
                      <label>OTP ( found in email sent )</label>
                      <md-input v-model="otp"></md-input>
                    </md-field>
  

                    <md-field>
                      <label>New Password</label>
                      <md-input v-model="password" type="password"></md-input>
                    </md-field>
  
                    <div class="flex flex-wrap justify-between my-5">
                        
                    </div>
                    <md-button @click="loginUser()" class="float-right oeno-button" :disabled="resettingpass">{{resettingpass?'SNDING RESET':'RESET'}}</md-button>
  
                  </div>
  
                </div>
              </div>
            </div>
      </div>
    </div>
  </template>
  
  <script>
  
  import {HTTP} from "@/axios.js"
  import { mapActions } from "vuex";
  
  
  export default{
    data() {
      return {
        email: "",
        otp: "",
        password: "",
        resettingpass: false,
      }
    },
    methods: {
      ...mapActions(["updateUserInfo","updateUserToken"]),        
      loginUser() {
  
        // let login now
  
        this.resettingpass = true;
        HTTP
          .post("/api/reset-password", {email: this.email, otp: this.otp, password: this.password}  )
          .then(response => {
  
            this.resettingpass = false;


            if (response.data.statuscode == 200) {


              this.$router.push('/login')

            } else {

              this.$notify({
                group: 'oeno',
                title: 'password Reset Error',
                text: 'Sorry there seems to have been an error processing your password reset, please try again later.',
                type: 'error',
              });

            }
  
  
          })
          .catch(error => {

            this.resettingpass = false;

            this.$notify({
                group: 'oeno',
                title: 'password Reset Error',
                text: 'Sorry there seems to have been an error processing your password reset, please try again later.',
                type: 'error',
              });
  
              console.log(error);

          });
  
      }
    }
  }
  </script>
  
  <style lang="scss">
  #page-login {
    .social-login-buttons {
      .bg-facebook { background-color: #1551b1 }
      .bg-twitter { background-color: #00aaff }
      .bg-google { background-color: #4285F4 }
      .bg-github { background-color: #333 }
    }
  }
  </style>
  